import React, {useEffect, useState} from "react";
import Carousel from 'react-bootstrap/Carousel';
import { Row, Col } from "react-bootstrap";
import "../carousel/carousel.css";
import Button from '../buttons/button';
import Logo_BalanzINT from "../../imgs/Logo_BalanzINT.png";
import LOGO_BALANZ_UNIVERSITY from "../../imgs/logos-fci/LOGO_banner_web_bu_idoneidad_05.svg";
import LOGO_fast_track from "../../imgs/logos-fci/fast_track.png";
import LOGO_new from "../../imgs/logos-fci/logo_new.png";
import Logo_Perfomance_II from "../../imgs/logos-fci/PERFORMANCE_II.svg";
import Logo_Ahorro from "../../imgs/logos-fci/LogoBC_FCI_AH_DOL.png";
import Logo_moneyMarket from "../../imgs/logos-fci/logo_fci_mm_dolares.png";
import Logo_dia_estudiante from "../../imgs/logos-fci/logo_dia_estudiante.png";
function ControlledCarousel() {
    const [index, setIndex] = useState(0);
    const [notxs, setNotXs] = useState(false);
    const [isType2, setIsType2] = useState(false);
    useEffect(() => {
        const handleXs = () => {
            const isntXs = window.innerWidth > 768;
            setNotXs(isntXs);
        };

        window.addEventListener('resize', handleXs);

        handleXs();

        return () => {
            window.removeEventListener("resize", handleXs);
        };
    }, []);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };
    useEffect(() => {
        const handleResize = () => {
          setIsType2(window.innerWidth <= 375);
        };
    
        window.addEventListener("resize", handleResize);
        handleResize(); // Llamada inicial para verificar el tamaño actual
    
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);

    return (
        <Carousel controls={true} activeIndex={index} onSelect={handleSelect} className="blnz-carousel" interval={6000}>
            <Carousel.Item className="type-5-v2">
                <div className="bg d-block d-lg-none" style={{ backgroundImage: "url(" + "/imgs/banner-semana-inversor-mobile.jpg" + ")", backgroundPosition: "center" }}></div>
                <div className="bg d-none d-lg-block" style={{ backgroundImage: "url(" + "/imgs/banner-semana-inversor-desktop.jpg" + ")", backgroundPosition: "center" }}></div>
                <div className="item-inner" >
                    <div className="container responsive">
                        <Carousel.Caption>
                            <h3  style={{animationDelay:"0.1s", color:"white", maxWidth:"100%", fontWeight:"700" }}  className="carousel-perfomance-ii "  >
                            Somos parte de la Semana Mundial del Inversor
                            <br/><span style={{fontWeight:"300"}}>7 al 11 de octubre</span>
                                <p className="animate__animated animate__fadeInUp" style={{ animationDelay: "0.2s", color: "var(--white)",marginTop:"2rem" }} >En Balanz impulsamos la educación financiera para que más personas <br/> puedan invertir en un mercado de capitales seguro, accesible y transparente.</p>
                            </h3>
                            
                            <div className="animate__animated animate__fadeInUp" style={{ animationDelay: "0.35s" }}>
                                <Button variant="secondary" text="Ver más" onClick={() => { window.open("https://balanz.in/3ZFK37G", "_blank") }}  />
                            </div>
                        </Carousel.Caption>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item className="type-5">
                <div className="bg d-block d-lg-none" style={{ backgroundImage: "url(" + "/imgs/banner_web_blanqueo_04.jpg" + ")", backgroundPosition: "center" }}></div>
                <div className="bg d-none d-lg-block" style={{ backgroundImage: "url(" + "/imgs/banner_web_blanqueo_01.jpg" + ")", backgroundPosition: "center" }}></div>
                <div className="item-inner" style={{ flexDirection: "column" }}>
                    <div className="container responsive">
                        <Carousel.Caption style={{ paddingTop: "1.5rem" }}>
                        <img src={LOGO_fast_track} alt="FCI" className="img-fluid logo-balanz-blanco" />
                        <h3 className="animate__animated animate__fadeInUp"  style={{ animationDelay:"0.1s", color:"#ffffff", borderColor:"var(--secondary)" }} >Blanqueá fácil, invertí bien 
                        </h3>
                            <p className="animate__animated animate__fadeInUp" style={{ animationDelay: "0.2s", color: "var(--white)" }} >Abrí tu cuenta especial (CERA) y hacé que tu blanqueo rinda al máximo.</p>
                            <div className="animate__animated animate__fadeInUp" style={{ animationDelay: "0.35s" }}>
                                <Button variant="secondary" text="Ver más" onClick={() => { window.open("https://balanz.com/balanz-university/blanqueo-de-capitales-que-tenes-que-saber/", "_blank") }}  />
                            </div>
                        </Carousel.Caption>
                    </div>
                </div>
            </Carousel.Item>
            
            
           <Carousel.Item className={`type-3 white`}>
                <div className="bg d-block d-lg-none" style={{ backgroundImage: "url(" + "/imgs/banner_mobile.jpg" + ")", backgroundPosition:"center"}}></div>
                <div className="bg d-none d-lg-block" style={{ backgroundImage: "url(" + "/imgs/banner_desktop.jpg" + ")", backgroundPosition:"center"}}></div>
                <div className="item-inner">
                    <div className="container">
                    <Carousel.Caption className="brand">
                    <Button variant="secondary" text="Ver más" onClick={() => { window.open("https://www.youtube.com/watch?v=_CN6kqknW54", "_blank") }} />
                    </Carousel.Caption>
                    </div>
                </div>
            </Carousel.Item> 
            <Carousel.Item className={`type-${isType2 ? 2 : 6} white`}>
                <div className="bg d-block d-lg-none" style={{ backgroundImage: "url(" + "/imgs/banner_asesoramiento.jpg" + ")", backgroundPosition:"center", width: "100%", height: "65%" }}></div>
                <div className="bg d-none d-lg-block" style={{ backgroundImage: "url(" + "/imgs/banner_asesoramiento.jpg" + ")", backgroundPosition:"center", width: "60%" }}></div>
                <div className="item-inner">
                    <div className="container">
                    <Carousel.Caption>
                        <h3 className="animate__animated animate__fadeInUp"  style={{ animationDelay:"0.1s", color:"var(--primary)", borderColor:"var(--secondary)", maxWidth:"none" }} >Invertir es confianza</h3>
                        <p  className="animate__animated animate__fadeInUp"  style={{ animationDelay:"0.2s", color:"var(--primary)" }} >En Balanz acompañamos tus inversiones con asesoramiento personalizado.
                        </p>
                        <div className="animate__animated animate__fadeInUp" style={{ animationDelay:"0.35s" }}>
                            <Button variant="secondary" text="Abrir cuenta" onClick={() => { window.open("https://balanz.com/abrir-cuenta-2.aspx?bam_campaign=1384", "_self") }} />
                        </div>
                    </Carousel.Caption>
                    </div>
                </div>
            </Carousel.Item> 
            
            <Carousel.Item className="type-5">
             <div className="bg d-block d-lg-none" style={{ backgroundImage: "url(" + "/imgs/Fondo_Banner_balanz_internacional_mobile.jpg" + ")", backgroundPosition:"center" }}></div>
            <div className="bg d-none d-lg-block" style={{ backgroundImage: "url(" + "/imgs/Fondo_Banner_balanz_internacional.jpg" + ")", backgroundPosition:"center" }}></div>
                <div className="item-inner" style={{flexDirection:"column"}}>
                    <div className="container responsive">
                    <Carousel.Caption style={{paddingTop:"1.5rem"}}>
                    <img src={Logo_BalanzINT} alt="FCI" className="img-fluid logo-balanz-blanco" />
                        <h3 className="animate__animated animate__fadeInUp inverti_text"  style={{ animationDelay:"0.1s", color:"var(--secondary)", borderColor:"var(--secondary)", maxWidth:"100%" }} >Invertí en el mundo,  <span className="line">sin escalas.</span></h3>
                    
                        
                        <p className="animate__animated animate__fadeInUp"  style={{ animationDelay:"0.2s", color:"var(--white)" }} >Operá tus instrumentos en el mercado americano.</p>
                        <div className="animate__animated animate__fadeInUp" style={{ animationDelay:"0.35s" }}>
                            <Button variant="secondary" text="Abrir cuenta" onClick={() => { window.open("https://bzmacp.page.link/BCI-web", "_blank") }} style={{marginTop:"2px", marginBottom:"2px"}}/>
                        </div>
                    </Carousel.Caption>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item className="type-1 white">
                <div className="bg animate__animated animate__fadeIn" style={{ backgroundImage: "url(" + "/imgs/bg-carousel-6.jpg" + ")" }}></div>
                <div className="item-inner">
                    <div className="container">
                    <Carousel.Caption>
                        <h3 className="animate__animated animate__fadeInUp"  style={{ animationDelay:"0.1s", color:"var(--primary)", borderColor:"var(--secondary)" }} >Fondos <span className="line">Balanz</span></h3>
                        <p  className="animate__animated animate__fadeInUp"  style={{ animationDelay:"0.2s", color:"var(--primary)" }} >Tenemos una gran variedad de Fondos en pesos y en dólares <br/>que se adaptan a cada necesidad.
                        </p>
                        <div className="animate__animated animate__fadeInUp" style={{ animationDelay:"0.35s" }}>
                            <Button variant="secondary" text="Ver más" onClick={() => { window.open("/inversiones/fondos/rentabilidades-fci", "_self") }} />
                        </div>
                    </Carousel.Caption>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item className="type-3 p-ii">
            <div className="bg d-block d-lg-none" style={{ backgroundImage: "url(" + "/imgs/banner-university-mobile.jpg" + ")", backgroundPosition:"center"}}></div>
                <div className="bg d-none d-lg-block" style={{ backgroundImage: "url(" + "/imgs/banner-university-desktop.jpg" + ")", backgroundPosition:"center"}}></div>
                <div className="item-inner">
                    <div className="container container-university">
                    <Carousel.Caption style={{paddingTop:"1.5rem"}}  className="carousel-perfomance-ii carousel-univeristy-box">
                    <h3 className="animate__animated animate__fadeInUp"  style={{ animationDelay:"0.1s", color:"#ffffff", borderColor:"var(--secondary)" }} >Balanz University
                        </h3>
                        <p className="animate__animated animate__fadeInUp font-resp carousel-univeristy-box-small"   style={{ animationDelay:"0.2s", color:"#ffffff"}} >
                        Educación financiera <br/> para el mundo real
                        </p>
                        <p className="animate__animated animate__fadeInUp" style={{ animationDelay: "0.2s", color: "var(--white)" }} >Conocé nuestros cursos y empezá a invertir mejor</p>
                        <div className="animate__animated animate__fadeInUp" style={{animationDelay:"0.35s" }}>
                            <Button variant="secondary" text="Ver más" onClick={() => { window.open("https://balanz.in/3LQxtdE", "_blank") }} />
                        </div>
                    </Carousel.Caption>
                    </div>
                </div>
            </Carousel.Item> 
            <Carousel.Item className="type-4" >
                <div className="bg animate__animated animate__fadeIn"
                     style={{ backgroundImage:"url(" + "/imgs/bg-carousel-9.jpg" + ")" }}>
                    <img alt="banner1" loading="lazy" src="/imgs/bg-carousel-9.jpg" />
                </div>
                <div className="item-inner">
                    <div className="container">
                    <Carousel.Caption>
                        <h6 className="animate__animated animate__fadeInUp"  style={{color:"var(--secondary)" }} >PACKS DE CEDEARS</h6>
                        <h3 className="animate__animated animate__fadeInUp"  style={{ animationDelay:"0.1s", color:"#ffffff", borderColor:"var(--secondary)" }} >Investment Ideas</h3>
                        <p className="animate__animated animate__fadeInUp"   style={{ animationDelay:"0.2s", color:"#ffffff" }} >Operá carteras de CEDEARs de las industrias más relevantes armadas por nuestros especialistas.</p>
                        <div className="animate__animated animate__fadeInUp" style={{animationDelay:"0.35s" }}>
                            <Button variant="secondary" text="Invertir" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                        </div>
                    </Carousel.Caption>
                    </div>
                </div>
            </Carousel.Item>
        </Carousel>
    );
}

export default ControlledCarousel;