import React from 'react'
import ReactPlayer from "react-player";
import '../auto_play_video/auto_play_video.css'
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
export const AutoPlayVideo = () => {
  return (
    <>
    <div className='video-player-container'>
    <ReactPlayer
      url='https://www.youtube.com/watch?v=_CN6kqknW54'
      playing={true}
      loop={true}
      muted={true}
      controls={true}
      className='video-player'
      />
   </div>
    </>
  );
};

