import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "gatsby";
import { Layout } from "../components/Layout";
import ControlledCarousel from "../components/carousel/carousel";
import NewsLetter from "../components/newsletter/newsletter.js";
import Image from "../components/image/image";
import Button from '../components/buttons/button'
import "./index.css";
import AbriTuCuenta from "../imgs/abri-tu-cuenta.svg";
import EmpezaAInvertir from "../imgs/empeza-a-invertir.svg";
import MonitoreaTuInversion from "../imgs/monitorea-tu-inversion.svg"
import ConsultaAUnAesor from "../imgs/consulta-asesor.svg"
import IFAImg from '../imgs/banners-ballanz-01.jpg'
import { SalWrapper } from "react-sal"
import Slider from "react-slick";
import { Helmet } from "react-helmet";
import { AutoPlayVideo } from "../components/auto_play_video/AutoPlayVideo";
const HomePage = () => {
    const pageTitle = 'La mejor plataforma de inversiones | Balanz';
    const pageDesc = 'Invertí en Cedears, Bonos, Fondos de Inversión, Futuros, Acciones y muchos activos financieros más. Tu cuenta de inversión sin costo de mantenimiento.';


    const slickFondosSettings = {
        centerMode: false,
        centerPadding: "0px",
        initialSlide: 0,
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: true,
        arrows: true,
        responsive: [
            {
                breakpoint: 2920,
                settings:"unslick"
            },
            {
                breakpoint: 992,
                settings: { centerMode: false, slidesToShow: 2, slidesToScroll: 1, infinite: false }
            },
            {
                breakpoint: 576,
                settings: { centerMode: false, slidesToShow: 1, slidesToScroll: 1, infinite: false, arrows: false }
            }
        ]
    };


    const page = (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "Balanz",
                        "alternateName": "balanz",
                        "url": "https://balanz.com/",
                        "logo": "https://clientes.balanz.com/images/header-logo.png",
                        "sameAs": [
                            "https://twitter.com/BalanzCapital",
                            "https://www.instagram.com/balanzcapital/",
                            "https://www.youtube.com/channel/UCl7iljPb3wACm-lOyqM1dHA",
                            "https://www.facebook.com/balanzcapital/",
                            "https://ar.linkedin.com/company/balanzcapital"
                        ]
                    }
                `}
                </script>
            </Helmet>

            <ControlledCarousel />
            {/*<Ticker tickerSettings={tickerSettings} list={Ticker_items} />*/}
            <section className="fullwidth bg-lt-grey tenes-acceso">
                <Container>
                    <div className="phrase">
                        <h2 className="mb-1"><span className=" primary-color">En Balanz hace más de 20 </span> <span className="primary-color">años acompañamos tus inversiones</span></h2>
                        <p style={{fontSize:"88%", fontWeight: "300" }}>con la tecnología más intuitiva del mercado.</p>
                    </div>
                </Container>
            </section>
            <section className="fullwidth nuestra-app bg-primary">
                <Container>
                    <Row className="gx-5 py-3 py-md-4 py-lg-5 justify-content-between">
                        <Col xs={12} md={5} className="d-flex align-items-center justify-content-center">
                            <h3 className="ash2 m-0 pb-4 pb-md-0"
                                data-sal="fade"
                                data-sal-delay="100"
                                data-sal-duration="300"
                            ><span className="bigger-title lt-font white-color">El tiempo de invertir es hoy.</span> </h3>
                        </Col>
                        <Col xs={12} md={7} className="d-lg-flex justify-content-lg-end align-items-center">
                             <AutoPlayVideo />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="fullwidth ahorrar">
                <SalWrapper>
                    <Container>
                        <Row className="justify-content-center g-3 g-md-3">
                            <h2>Abrí hoy tu cuenta de inversión</h2>
                            <Col xs={6} sm={6} md={3} lg={3}>
                                <div className="forma-item cuenta ratio ratio-14x10"
                                    data-sal="fade"
                                    data-sal-delay="100"
                                    data-sal-duration="500"
                                    data-sal-easing="ease"
                                >
                                    <div className="ratio-inner">
                                        <a href="/abrir-cuenta-2.aspx" className="d-flex flex-column align-items-center justify-content-center" target="_blank">
                                            <img src={AbriTuCuenta} alt="Abrí tu cuenta" className="icono-formas" />
                                            <h6>Abrí tu cuenta</h6>
                                        </a>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={6} sm={6} md={3} lg={3}>
                                <div className="forma-item invertir  ratio ratio-14x10"
                                    data-sal="fade"
                                    data-sal-delay="200"
                                    data-sal-duration="500"
                                    data-sal-easing="ease"
                                >
                                    <div className="ratio-inner">
                                        <a href="https://clientes.balanz.com/" className="d-flex flex-column align-items-center justify-content-center" target="_blank">
                                            <img src={EmpezaAInvertir} alt="Abrí tu cuenta" className="icono-formas" />
                                            <h1 className="ash6">Empezá a <br/>invertir</h1>
                                        </a>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={6} sm={6} md={3} lg={3}>
                                <div className="forma-item monitorea ratio ratio-14x10"
                                    data-sal="fade"
                                    data-sal-delay="300"
                                    data-sal-duration="500"
                                    data-sal-easing="ease"
                                >
                                    <div className="ratio-inner">
                                        <a href="https://clientes.balanz.com/" className="d-flex flex-column align-items-center justify-content-center" target="_blank">
                                            <img src={MonitoreaTuInversion} alt="Abrí tu cuenta" className="icono-formas" />
                                            <h6>Monitoreá <br />tus inversiones</h6>
                                        </a>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={6} sm={6} md={3} lg={3}>
                                <div className="forma-item asesor ratio ratio-14x10"
                                    data-sal="fade"
                                    data-sal-delay="400"
                                    data-sal-duration="500"
                                    data-sal-easing="ease"
                                >
                                    <div className="ratio-inner">
                                        <Link to="/contacto" className="d-flex flex-column align-items-center justify-content-center">
                                            <img src={ConsultaAUnAesor} alt="Abrí tu cuenta" className="icono-formas" />
                                            <h6>Consultá a <br/>un asesor</h6>
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </SalWrapper>
            </section>
            <section className="fullwidth bg-lt-grey nuestros-fondos">
                <Container>
                    <h2>Nuestros Fondos de Inversión</h2>
                    <Row id="box" className="scrolling-wrapper-flexboxout g-0 ">
                        <Col xs={12}>
                            <Slider className="d-lg-flex justify-content-between slick-nuestros-fondos" {...slickFondosSettings}>
                                <div className="fondo-container slick-item-container">
                                    <Link to={"/inversiones/fondos/money-market"}>
                                        <Card className="fondo-card shadow ratio ratio-14x10">
                                            <Card.Body>
                                                <Card.Title as="h3"><span className="line"><span className='volanta'>FONDO BALANZ</span><br />Money Market</span></Card.Title>
                                                <Card.Text>
                                                    Ideal para invertir de manera eficiente tu dinero de gastos mensuales hasta que tengas que usarlo.
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </div>
                                <div className="fondo-container slick-item-container" >
                                    <Link to={"/inversiones/fondos/performance-iii"}>
                                        <Card className="fondo-card shadow ratio ratio-14x10">
                                            <Card.Body>
                                                <Card.Title as="h3"><span className="line"><span className='volanta'>FONDO BALANZ</span><br/><span className="nonbreak">Performance III <br/> Corporativo
                                                </span></span></Card.Title>
                                                <Card.Text>
                                                    El objetivo del fondo es maximizar los rendimientos ajustados por riesgo a través de la inversión en activos de renta fija corporativa.
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </div>
                                <div className="fondo-container slick-item-container">
                                    <Link to={"/inversiones/fondos/acciones"}>
                                        <Card className="fondo-card shadow ratio ratio-14x10">
                                            <Card.Body>
                                                <Card.Title as="h3"><span className='volanta'>FONDO BALANZ</span><br /><span className="line"><span className="nonbreak">Acciones
                                                </span></span></Card.Title>
                                                <Card.Text>
                                                El fondo busca obtener un rendimiento superior al S&P Merval en el largo plazo.</Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </div>
                            </Slider>
                        </Col>
                    </Row>
                    <Row className='text-center pt-md-5 pt-4 pb-md-3 pb-2'>
                        <Link to="/inversiones/fondos">
                            <div className="p-3"><Button variant="secondary" text="Ver todos" /></div>
                        </Link>
                    </Row>

            </Container>
        </section>
      <section className="fullwidthbg independent">
          <SalWrapper>
            <Container>
                <Row>
                    <Col xs={12} md={7} lg={7}  className="column col-text">
                        <div className="section-content pt-sm-5 pt-4">
                            <p className="volanta">Independent Financial Advisor</p>
                            <h2 className="titulo" >Combiná tu independencia con el respaldo del mejor equipo</h2>
                            <p className="texto">Conocé el programa de Asesores Financieros Independientes y convertite en un especialista en oportunidades.</p>
                            <div className="cta py-3">
                                <Link to="/independent-financial-advisor">
                                    <Button variant="nofill-2" text="Contactanos"/>
                                </Link>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={4} className="column col-img">
                        <div className="section-content"
                             data-sal="slide-up"
                             data-sal-duration="500"
                             data-sal-easing="ease"
                        >
                            {/* <Image
                                className="section-image lift ratio ratio-1x1"
                                src="independent_picture.jpg"
                                alt="Independent Financial Advisors"
                            /> */}
                                    <img
                                        alt="Independent Financial Advisors"
                                        className="section-image lift ratio ratio-1x1"
                                        src={IFAImg} />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </SalWrapper>
            </section>
            <section className="fullwidth semi inversiones py-lg-5 py-sm-4 py-3">
                <SalWrapper>
                    <Container>
                        <Row className="gx-lg-5 gx-md-4 gx-3 gy-3" xs={1} md={2}>
                            <Col>
                                <article className="semiwidthbg invertir p-lg-5 py-md-4 px-md-4 py-3 px-4"
                                    data-sal="slide-right"
                                    data-sal-duration="500"
                                    data-sal-easing="ease"
                                >

                                    <div className="section-content mx-2">
                                        <p className="volanta">Inversiones Internacionales</p>
                                        <h3 className="titulo" >Invertí en el mundo</h3>
                                        <p className="texto">Invertí en CEDEARs de Apple, Tesla, Coca Cola, Amazon, Google y más.</p>
                                        {/*<div className="cta py-3 py-sm-2 py-md-1">
                                          <Button variant="white" text="Invertir" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                                      </div>*/}
                                        <Link to="/inversiones/cedears">
                                            <div className="cta py-3 py-sm-2 py-md-1"><Button variant="white" text="Ver más" /></div>
                                        </Link>
                                    </div>
                                </article>
                            </Col>
                            <Col>
                                <article className="Renta_fija p-lg-5 py-md-4 px-md-4 py-3 px-4"
                                    data-sal="slide-left"
                                    data-sal-duration="500"
                                    data-sal-easing="ease"
                                >
                                    <div className="section-content mx-2">
                                    <p className="volanta">EDUCACIÓN FINANCIERA </p>
                                        <h3 className="titulo" >¿Qué son las Letras?</h3>
                                        <p className="texto">Conocé todo sobre las LECER, LEDES, LELITES, LELIQ Y LELINK.</p>
                                        <div className="cta py-3 py-sm-2 py-md-1">
                                        
                                            <Link to={"/balanz-university/que-son-las-letras/"}>
                                                <Button variant="nofill-2" text="Ver más" />
                                            </Link>
                                        </div>
                                    </div>
                                </article>
                            </Col>
                        </Row>
                    </Container>

                </SalWrapper>
            </section>

            <section className="fullwidthbg invertir d-none">
                <Container>
                    <Row>
                        <Col>
                            <div className="section-content">
                                <p className="volanta">Inversiones Internacionales</p>
                                <h2 className="titulo" >Invertí en el mundo</h2>
                                <p className="texto">Invertí en CEDEARs de Apple, Microsoft, Coca Cola, Disney, Amazon y más.</p>
                                <div className="cta">
                                    <Button variant="white" text="Invertir" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>
            <section className="fullwidthbg appdownload">
                <Container>
                    <Row className="d-flex align-items-stretch">
                        <Col>
                            <div className="section-content">
                                <p className="volanta">Descargá la app</p>
                                <h3 className="titulo" >Invertí desde <br />tu celular</h3>
                                <p className="texto">Podés abrir tu cuenta, ver las <br /> cotizaciones en tiempo real e invertir <br /> desde cualquier lugar</p>
                                <div className="cta">
                                    <a href="https://play.google.com/store/apps/details?id=com.mobile.balanz.droid&utm_source=Website&utm_medium=Banner&utm_campaign=Android" target="_blank">
                                        <Image
                                            className="store-img"
                                            src="PlayStore.png"
                                            alt="GStore"
                                        /></a>
                                    <a href="https://apps.apple.com/us/app/balanz/id1318206099?utm_source=Website&utm_medium=Banner&utm_campaign=IOS" target="_blank">
                                        <Image
                                            className="store-img"
                                            src="AppStore.png"
                                            alt="AStore"
                                        />
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="fullwidth newsletter">
                <Container>
                    <Row className="d-flex justify-content-center">
                        <Col xs="12" md="11">
                            <NewsLetter
                                Text="Recibí nuestra información <br /> y enterate de las últimas novedades"
                                ClassNameDisplay="section-content"
                                ClassNameInput="custom-email-input"
                                ButtonStyle="secondary"
                                ButtonText="Registrate"
                                IDCampaignAction={1057}
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );

    return (
        <main>
            <Layout title={pageTitle} description={pageDesc} childrem={page} category={"home"}></Layout>
        </main>
    );
};

// Step 3: Export your component
export default HomePage;
